
import { defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      role : store.getters.currentUser.con_role_web,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;
      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const signOut = () => {
      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      signOut,
      state,
    };
  },
});
