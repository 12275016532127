
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

export default defineComponent({
  name: "deploy-overview",
  components: {
    SearchAndFilter
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const state = reactive({
      constructeur: "",
      initialMyList: [] as any,
      myList: [] as any,
      drawer : false,
      rSociete: "",
      tableFilters: [],
      excelFormat: [],
    });



    onMounted(async () => {
      
      let myList = await getAxios("/getAllSociete");
      state.initialMyList = myList.records;
      state.myList = myList.records;  

  

      let societe = await getAxios("/getSociete");
      state.rSociete = societe.records[0];

      

    });

    const selectSoc = async (item) => {
      const result = await getAxios("/changeSociete/" + item.soc_seq);
      
      state.rSociete = item;
      emit("reloadRouter", 1);      
      state.drawer = false;
    }

    return {
      selectSoc,
      state
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
