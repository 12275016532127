
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import { useRouter, useRoute } from "vue-router";

import mAxiosApi from "@/api";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;

    // localStorage.setItem('storedData', this.input)

    onMounted(async () => {
      let storedConst = localStorage.getItem('storedConst');
      if (!storedConst) {
        let myList = await getAxios("/getConstLogo");
        localStorage.setItem('storedConst', JSON.stringify(myList.results))
      } 

      
      // const storedConst = JSON.parse(localStorage.getItem('storedConst'));
    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const backRoute = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) {
        // router.push("/stillboard/commandes");
        router.go(-1);

      }
    };

    const displayBack = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      backRoute,
      displayBack,
      reloadRouter
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}

