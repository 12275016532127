
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
// import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import mAxiosApi from "@/api";
// import MainMenuConfigOld from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {

    const state = reactive({
      MainMenuConfig: [],
      logoSoc : "",
    });

    const { t, te } = useI18n();
    // const store = useStore();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    /*
    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });
    */

   // console.log("MainMenuConfig",MainMenuConfig);


    onMounted(async () => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }


      const myList = await getAxios("/getMenu");

      state.MainMenuConfig = myList.results;

      state.logoSoc = myList.logo ? "data:image/jpg;base64," + myList.logo : "";

      // console.log("MainMenuConfigOld",MainMenuConfigOld);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      state,
      asideMenuIcons,
      version,
      translate,
    };
  },
});


export async function getAxios(request: string): Promise<any> {

  const response = await mAxiosApi.get(request);
  return response.data;

}

