<template>


  <div class="d-flex align-items-center ms-1 ms-lg-3" v-if="state.rSociete.soc_nom_societe" >
      <div class="btn btn-icon btn-light position-relative w-auto p-4 h-30px  h-md-40px symbol-label fs-2 fw-bold text-dark" 
          @click="state.drawer=true" v-if="state.myList.length>0">
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/abstract/abs027.svg" />
        </span>
        <span class="fs-6 m-2">
          {{ state.rSociete.soc_nom_societe }}
        </span>
      </div>
      <span class="fs-6 m-2" v-else>
        {{ state.rSociete.soc_nom_societe }}
      </span>
    </div>
    <el-drawer direction='rtl' v-model="state.drawer" :with-header="false" size="20%" modal-class="modal-transp">
      <div class="card shadow-none" style="width: 100%; height: 100%;">
        <div class="card-header" id="kt_activities_header" style="background: white;">
          
          <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
            <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left p-1 fs-4" ></i></button>
            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              Changer de société
            </h1>
          </div>

        
        <SearchAndFilter
            :listInitial="state.initialMyList"
            :filters="state.tableFilters"
            @returnSearch="state.myList = $event"
            widthDecalage="-500"
        ></SearchAndFilter>

        </div>



        <div class="card-body position-relative bg-light" style="overflow: auto !important;  height: calc(100vh - 100px);">
            
            <div class="menu menu-column menu-active-bg menu-hover-bg-primary menu-title-gray-700 fs-6 menu-rounded w-100" id="#kt_aside_menu" data-kt-menu="true">             
              <template  v-for="(item, index) in state.myList" :key="index" >
                <div class='menu-item p-0'>
                  <a @click='selectSoc(item)' class='menu-link'>
                    <span class='menu-title p-0'><span class='bullet bg-dark me-5'></span>{{ item.soc_nom_societe }}</span>
                  </a>
                </div>
              </template>
            </div>

        </div>
      </div>
    </el-drawer>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

export default defineComponent({
  name: "deploy-overview",
  components: {
    SearchAndFilter
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const state = reactive({
      constructeur: "",
      initialMyList: [] as any,
      myList: [] as any,
      drawer : false,
      rSociete: "",
      tableFilters: [],
      excelFormat: [],
    });



    onMounted(async () => {
      
      let myList = await getAxios("/getAllSociete");
      state.initialMyList = myList.records;
      state.myList = myList.records;  

  

      let societe = await getAxios("/getSociete");
      state.rSociete = societe.records[0];

      

    });

    const selectSoc = async (item) => {
      const result = await getAxios("/changeSociete/" + item.soc_seq);
      
      state.rSociete = item;
      emit("reloadRouter", 1);      
      state.drawer = false;
    }

    return {
      selectSoc,
      state
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>

<style>

.menu-link.active {
  font-weight: 700;
  background: #003553 !important;
  color: white !important;
}
.menu-link.active .menu-title {
  color: white !important;
}

</style>